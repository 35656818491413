import React, { Component } from 'react';
import './ImageCaption.css';

class ImageCaption extends Component {
    constructor(props) {
      super(props);
    }
  
    render() {
      return (
        <div className="ImageCaption">
          <p>{this.props.caption}</p>
        </div>
      );
    }
  }
  
  export default ImageCaption;