import React, { Component } from 'react';
import Responsive from 'react-responsive';
import { Helmet } from 'react-helmet'

import './App.css';

import NameBox from './NameBox';
import Copyright from './Copyright';
import ImageCaption from './ImageCaption';
import LinkList from './LinkList';

import img01 from './img01.jpg';
import img02 from './img02.jpg';
import img03 from './img03.jpg';

const backgroundImages = [
  [img01, "Royal Botanic Gardens, Melbourne, Victoria"],
  [img02, "Boboli Gardens, Florence, Italy"],
  [img03, "Larnach Castle, Dunedin, New Zealand"]
];

const cycleInterval = 20000;

const cspConfig = {
  "default-src": [
    "'none'",
  ],
  "script-src": [
    "https://www.googletagmanager.com",
    "https://www.google-analytics.com",
    "https://www.gstatic.com",
    "'self'"
  ],
  "img-src": [
    "https://www.google-analytics.com",
    "'self'"
  ],
  "style-src": [
    "https://www.gstatic.com",
    "https://fonts.gstatic.com",
    "https://fonts.googleapis.com",
    "'self'"
  ],
  "font-src": [
    "https://fonts.gstatic.com"
  ],
  "object-src": [
    "'none'"
  ],
  "manifest-src": [
    "'self'"
  ],
  "connect-src": [
    "'self'"
  ],
  "base-uri": [
    "'none'"
  ],
  "form-action": [
    "'self'"
  ]
}

// basic code taken from here: https://scotthelme.co.uk/csp-nonces-the-easy-way-with-cloudflare-workers/
const buildCspHeader = (cspConfig, nonce = null) => {
  let directives = []

  Object.keys(cspConfig).forEach(function(directive) {
    let values = Array.from(cspConfig[directive])
    values.forEach(function(value, key) {
      if (nonce && value === "'nonce'") {
        values[key] = "'nonce-" + nonce + "'"
      } else if (nonce === null && value === "'nonce'") {
        values.splice(key, 1)
      }
    })
    if (values.length === 0) {
      directives.push(directive)
    } else {
      directives.push(directive + ' ' + values.join(' '))
    }
  })

  return directives.join('; ')
}

const Desktop = props => <Responsive {...props} minWidth={992}/>;
const Tablet = props => <Responsive {...props} minWidth={768} maxWidth={991}/>;
const Mobile = props => <Responsive {...props} maxWidth={767}/>;
const Portrait = props => <Responsive {...props} orientation="portrait"/>;
const Landscape = props => <Responsive {...props} orientation="landscape"/>;
const ShortDisplay = props => <Responsive {...props} maxHeight={500}/>;
const LongDisplay = props => <Responsive {...props} minHeight={501}/>;

class App extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      counter: 1,
      imageDescription: backgroundImages[0][1]
    }
    this._b = null;
  }

  componentDidMount() {
    this.preloadImages();
    window.setTimeout(this.changeBackgroundImage.bind(this), cycleInterval);
  }

  preloadImages() {
    backgroundImages.forEach((img) => {
      const i = new Image();
      i.src = img[0];
    });
  }

  changeBackgroundImage() {
    this._b.style.backgroundImage = "url(" + backgroundImages[this.state.counter % backgroundImages.length][0] + ")"
    this.setState({
      counter: this.state.counter + 1,
      imageDescription: backgroundImages[this.state.counter % backgroundImages.length][1]
    });
    window.setTimeout(this.changeBackgroundImage.bind(this), cycleInterval);
  }

  render() {
    return (
        <div className="App-background-image no-flick" ref={(e) => {this._b = e}}>
          <Helmet>
            <title>rjk.com | Richard Kendall</title>
            <meta http-equiv="Content-Security-Policy" content={buildCspHeader(cspConfig)} />
          </Helmet>
          <Desktop>
            <NameBox/>
            <ImageCaption caption={this.state.imageDescription}/>
            <Copyright/>
            <LinkList position="bottom-right"/>
          </Desktop>
          <Tablet>
            <LongDisplay>
              <NameBox/>
              <ImageCaption caption={this.state.imageDescription}/>
              <Copyright/>
              <LinkList position="bottom-right"/>
            </LongDisplay>
            <ShortDisplay>
              <NameBox small={true} orientation="landscape"/>
              <LinkList position="right"/>
              <Copyright position="right"/>
            </ShortDisplay>
          </Tablet>
          <Mobile>
            <Portrait>
              <NameBox small={true} orientation="portrait"/>
              <LinkList position="left"/>
              <Copyright/>
            </Portrait>
            <Landscape>
              <NameBox small={true} orientation="landscape"/>
              <LinkList position="right"/>
              <Copyright position="right"/>
            </Landscape>
          </Mobile>
        </div>
    );
  }
}

export default App;
