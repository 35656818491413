import React, { Component } from 'react';
import './NameBox.css';


class NameBox extends Component {
    constructor(props) {
      super(props);
    }
  
    render() {
      var styles = ["NameBox", "NameBox-big", "NameBox-text", "NameBox-lighten"].join(" ");

      if (this.props.small) {
        if(this.props.orientation === "portrait") {
          styles = ["NameBox", "NameBox-small", "NameBox-text", "NameBox-lighten"].join(" ");
        }
        if(this.props.orientation === "landscape") {
          styles = ["NameBox", "NameBox-small-landscape", "NameBox-text", "NameBox-lighten"].join(" ");
        }
      }

      var smalltextStyle = "NameBox-smalltext";
      if(this.props.small) {
        if(this.props.orientation === "landscape") {
          smalltextStyle = "NameBox-smalltext-mobile-landscape";
        } else {
          smalltextStyle = "NameBox-smalltext-mobile";
        }
      }

      return (
        <div className={styles}>
          <p>Richard</p>
          <p style={{
              marginBottom: "-10px"
          }}>James</p>
          <p>Kendall</p>
          
          <p className={smalltextStyle}>Computer Scientist | Tinkerer</p>
        </div>
      );
    }
  }
  
  export default NameBox;