import React, { Component } from 'react';
import './Copyright.css';

class Copyright extends Component {
    constructor(props) {
      super(props);
    }
  
    render() {
      var styles = ["Copyright"];
      if(this.props.position === "right") {
        styles.push("Copyright-bottom-right");
      } else {
        styles.push("Copyright-bottom-left")
      }
      return (
        <div className={styles.join(" ")}>
          <p>&copy; 2001 - {new Date().getFullYear()} Richard James Kendall.</p>
        </div>
      );
    }
  }
  
  export default Copyright;