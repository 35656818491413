import React, { Component } from 'react';
import './LinkList.css';

const Links = [
	{
		name: "Contact",
		type: "External",
		url: "https://rjk.codes/contact/",
		target: "_blank"
	},
	{
		name: "About",
		type: "External",
		url: "https://rjk.codes/about/",
		target: "_blank"
	},
	{
		name: "Code",
		type: "External",
		url: "https://github.com/richardjkendall",
		target: "_blank"
	},
	{
		name: "Writing",
		type: "External",
		url: "https://blog.richardjameskendall.com/",
		target: "_blank"
	},
	{
		name: "LinkedIn",
		type: "External",
		url: "https://www.linkedin.com/in/richardjk/",
		target: "_blank"
	},
	{
		name: "Twitter",
		type: "External",
		url: "https://twitter.com/richardjkendall",
		target: "_blank"
	}
];

class LinkList extends Component {
	constructor(props) {
	  super(props);
	}
  
	openLink(id) {
		console.log("Opening link with ID " + id);
		if(Links[id].type === "External") {
			window.open(Links[id].url, Links[id].target);
		}
	}

	render() {
		var styles = ["LinkList"];
		var linkItems = [];
		for(var i = 0;i < Links.length;i++) {
			linkItems.push(
				<li key={"link_" + i}>
					<div className="LinkList-link" onClick={this.openLink.bind(this, i)}>{Links[i].name}</div>
				</li>
			)
		}
		if(this.props.position === "bottom-right") {
			styles.push("LinkList-bottom");
		} else if(this.props.position === "left") {
			styles.push("LinkList-left");
		} else if(this.props.position === "right") {
			styles.push("LinkList-right");
		}
	  return (
		<div className={styles.join(" ")}>
		  <ul>
			{linkItems}
		  </ul>
		</div>
	  );
	}
  }
  
  export default LinkList;
